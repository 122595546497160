export const MAIN_LOAD_TRUE = 'MAIN_LOAD_TRUE';
export const MAIN_LOAD_FALSE = 'MAIN_LOAD_FALSE';
export const HOME_SCREEN_DATA = 'HOME_SCREEN_DATA';
export const PROJECT_DATA = 'PROJECT_DATA';
export const TESTIMONIAL_DATA = 'TESTIMONIAL_DATA';
export const CARRIER_DATA = 'CARRIER_DATA';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';
// export const CREATE_LEAD = "CREATE_LEAD"
export const GET_WEB_HOME_SCREEN_INFO="GET_WEB_HOME_SCREEN_INFO";



