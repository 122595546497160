import React from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import './style.scss'
import './style.css'
import KnsIcon from '../../Images/JANASENA .png'
import { NavHashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
const headers = [
    {
        "showMedium": true,
        "label": "Home",
        "showType": "true",
        "path": "home"
    },
    {
        "showMedium": true,
        "label": "About Us",
        "showType": "true",
        "path": "#aboutUs"
    },
     {
        "showMedium": true,
        "label": "Projects",
        "showType": "true",
        "path": "#projects"
    }, 
    // {
    //     "showMedium": true,
    //     "label": "Blogs",
    //     "showType": "true",
    //     "path": "#blogs"
    // }, 
    {
        "showMedium": true,
        "label": "Testimonials",
        "showType": "true",
        "path": "#testimonial"
    }
]
const Footer = () => {
    const renderMenu = (item, i) => {
        // const show = item.showMedium ? 
        return <li key={item.label} className={i == 0 ? `Linkactive ${item.showType}` : `${item.showType}`}>
            {/* <a href={item.path} >{item.label}</a> */}
            <NavHashLink to={`/${item.path}`} >{item.label}</NavHashLink>
        </li>
    }
    return (
        <div className="footer">
            <Grid container className="footer-list">
                <Grid item xs={6} md={3}>
                    <img className='logoicon' src={KnsIcon} />
                </Grid>
                {/* <Grid item xs={6} md={3}>
                <Typography variant="h7" gutterBottom component="div"> <img className='logoicon' src={KnsIcon} /></Typography>
                <br/>   <br/>   <br/>   <br/>   <br/>   <br/>
                    <ul >
                        <li> Our mission is to engage in issues that are of concern to individuals, families and communities through an uncompromising commitment to create outstanding living, work and leisure environments.</li>
                      
                    </ul>
                </Grid> */}
                <Grid item xs={6} md={3}>

                    <ul className="header" >
                        {
                            headers.map((item, i) => {
                                return renderMenu(item, i)
                                // <div key={i} style={{display : !item.showMedium ? 'flex' : 'none'}} className={i == 0 ? "active" : ""}>{item.label}</div>
                            })
                        }
                    </ul>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography variant="h7" gutterBottom component="div"><b>Company Info</b></Typography>
                    <ul className="header">
                        <li>
                            <Link
                                to={{ pathname: "/CompanyInfo/TeamsofService" }}
                            >
                                Terms of Service
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={{ pathname: "/CompanyInfo/Disclaimer" }}
                            >
                                Disclaimer
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={{ pathname: "/CompanyInfo/PrivacyPolicy" }}
                            >
                                Privacy Policy
                            </Link>
                        </li>

                    </ul>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography variant="h7" gutterBottom component="div"><b>Connect With Us</b></Typography>
                    <ul>
                        <li> Twitter</li>
                        <li>Facebook</li>
                        <li>Instagram</li>
                    </ul>
                </Grid>
            </Grid>
        </div>
    );
};

export default Footer;