import logo from './logo.svg';
import './App.css';
import {Routing} from './components/routers'
import ClientTestimonials from './components/ClientTestimonials'
import Blogs from './components/Blogs'
import Carrier from './components/Carrier'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/Navbar';

function App() {
  return (
    <div className="App">
      {/* <Router>
      <Switch>
        <Route>
        <NavBar/>
        <Home />
        <ClientTestimonials /> 
         <Carrier />
        </Route>
        </Switch>
        </Router> */}
        <Routing />
    </div>
  );
}

export default App;
