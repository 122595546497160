import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
// import { , createTheme } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const THEME = createTheme({
  typography: {
   "fontFamily": `"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  },
  // 
  palette: {
    primary: {
      main: '#b5ddd1',
    },
    // you can add more options
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <App />
    </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
